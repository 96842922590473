<template>
  <div class="mb-5">
      <Swiper :sliderImages="swiperData"/>
      <div id="about" class="mt-4 mb-5">
        <h1>{{$t('TITLE.ABOUT_US')}}</h1>
      </div>
      <div class="d-flex px-5 justify-content-between company-services">
          <div class="google-map-container">
            <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=170,%20Jalan%202/3a,%20Pusat%20Bandar%20Utara%20Selayang,%2068100%20Batu%20Caves,%20Wilayah%20Persekutuan%20Kuala%20Lumpur+(HPO%20FRUITS%20SDN%20BHD)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
          </div>
          <div class="company-description text-left">
            <h3>{{$t('COMPANY.ESTABLISHED_YEAR', {'year':2019})}}</h3>
            <p v-html="$t('COMPANY.INFO.HPO_FRUITS')"></p>
            <p>{{$t('COMPANY.HISTORY.HPO_FRUITS.H_1')}}</p>
            <div class="mt-5">
              <h4>{{$t("ACTION.CONTACT_US")}}</h4>
              <p>{{$t("ACTION.EMAIL")}} 📧  &nbsp&nbsp : <a href = "mailto: hpofruitssdnbhd@gmail.com">hpofruitssdnbhd@gmail.com</a></p>
              <div class="d-flex">
                <p>{{$t("ACTION.ADDRESS")}} &nbsp&nbsp : &nbsp</p>
                <p>No.170 & 172, Jalan 2/3A, <br/>
                   Bandar Baru Utara, KM12, Jalan Ipoh, <br/>
                   68100, Selayang</p>
              </div>
              <div class="d-flex"> 
                <p>{{$t('ACTION.PHONE_NO')}} : &nbsp</p>
                <p><a href="tel:+60177478849">017-747 8849</a></p>
              </div>
              <Icons
                :iconContainerSize="'50%'"
                :iconSize="'25%'"
                :hasFacebook="true"
                :hasWhatsapp="true"
                :facebookLink="'https://www.facebook.com/HPO-Fruits-SDN-BHD-Selayang-106386934078460'"
                :whatsappLink="'https://api.whatsapp.com/send?phone=60162123506'"/>
            </div>
          </div>
      </div>


  </div>
</template>

<script>
import swiperImageData from '@/data/swiperImage'
import Swiper from '@/components/Swiper'
import Icons from '@/components/Icons'
export default {
  name:"HPO",
  components:{
    Swiper,
    Icons
  },
    data(){
    return{
      swiperData: {...swiperImageData.hpo}
    }
  }
}
</script>

<style lang="scss" scoped>
.google-map-container{
  width: 35%;
  height:45vh;
  position: relative;

  iframe{
    position: absolute;
    left:0;
  }
}

.company-description{
  width:60%
}

@media screen and (max-width:600px){
    .company-services{
        flex-direction: column-reverse;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .company-description, .google-map-container{
      width: 100%;
    }
}


</style>